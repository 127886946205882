import Navbar from '../components/landing/Navbar';
import Footer from '../components/landing/Footer';

export default function TermsOfService() {
    return (
        <div className="bg-white">
            <Navbar />
            <div className="min-h-screen pt-20">
                <div className="max-w-4xl mx-auto px-6 py-16">
                    <h1 className="text-3xl font-bold text-slate-900 mb-8">Terms of Service</h1>
                    <p className="text-slate-600 mb-4">Effective Date: 11/12/2024</p>

                    <p className="text-slate-700 mb-8">
                    Welcome to TrulyPPC (the "Site"), owned and operated as a proprietary business under Udyam Registration Number UDYAM-CG-14-0093047. By accessing and using this Site, you agree to comply with these Terms of Service ("Terms"). Please read them carefully.
                    </p>

                    {/* Section 1: General Terms */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-4">1. General Terms</h2>
                        <p className="text-slate-700 mb-4">
                            By using the Site, you agree to be bound by these Terms, along with our Privacy Policy and any additional terms applicable to specific sections of the Site or services provided by TrulyPPC. Accessing the Site in any manner, whether automated or otherwise, constitutes your agreement to these Terms.
                        </p>
                        <p className="text-slate-700">
                            We reserve the right to update these Terms at any time. Updates will be posted on this page, and continued use of the Site after such changes constitutes acceptance of the updated Terms.
                        </p>
                    </section>

                    {/* Section 2: Intellectual Property Rights */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-4">2. Intellectual Property Rights</h2>

                        <h3 className="text-xl font-semibold text-slate-800 mb-2">Our Limited License to You</h3>
                        <p className="text-slate-700 mb-4">
                            This Site and its content, including but not limited to text, graphics, logos, and tools, are the property of TrulyPPC and are protected by copyright, trademark, and other intellectual property laws. You may access and use the Site solely for personal, non-commercial purposes.
                        </p>

                        <p className="text-slate-700 mb-2">You may not:</p>
                        <ul className="list-disc pl-6 text-slate-700 mb-4">
                            <li>Modify, copy, reproduce, republish, upload, post, transmit, sell, or distribute any material from the Site without prior written permission.</li>
                            <li>Use our resources for commercial gain unless explicitly authorized.</li>
                        </ul>

                        <h3 className="text-xl font-semibold text-slate-800 mb-2">Your License to Us</h3>
                        <p className="text-slate-700">
                            By submitting content (e.g., testimonials, comments, or feedback) through the Site, you grant TrulyPPC a royalty-free, perpetual, irrevocable, non-exclusive license to use, reproduce, modify, and distribute the material in any medium for marketing or other purposes. You warrant that your submissions do not infringe on the rights of third parties.
                        </p>
                    </section>

                    {/* Section 3: Limitations of Liability */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-4">3. Limitations of Liability</h2>
                        <p className="text-slate-700 mb-4">
                            TrulyPPC provides services and content on an "as-is" basis and does not guarantee specific results, such as improved return on ad spend (ROAS) or campaign success.
                        </p>
                        <p className="text-slate-700 mb-2">To the fullest extent permitted by law, TrulyPPC disclaims liability for:</p>
                        <ul className="list-disc pl-6 text-slate-700 mb-4">
                            <li>Errors, inaccuracies, or interruptions on the Site.</li>
                            <li>Losses or damages resulting from the use of our services or reliance on information provided on the Site.</li>
                        </ul>
                        <p className="text-slate-700">
                            If you are dissatisfied with the Site or services, your sole remedy is to discontinue use.
                        </p>
                    </section>

                    {/* Section 5: Refund Policy */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-4">5. Refund Policy</h2>
                        <p className="text-slate-700 mb-4">Refunds are available under the following conditions:</p>
                        <ul className="list-disc pl-6 text-slate-700">
                            <li>A refund request must be submitted within 7 days of purchase.</li>
                            <li>Refunds are issued only for unused services.</li>
                            <li>To request a refund, contact us at contact@trulyppc.com.</li>
                        </ul>
                    </section>

                    {/* Section 6: Privacy and Cookies */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-4">6. Privacy and Cookies</h2>
                        <p className="text-slate-700">
                            Please review our <a href="/privacy-policy" className="text-orange-500 hover:text-orange-600 underline">Privacy Policy</a> for
                            details on how we collect, use, and protect your data. We use cookies to enhance your browsing experience and may share
                            anonymized data with trusted third-party analytics tools.
                        </p>
                    </section>

                    {/* Section 7: Governing Law */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-4">7. Governing Law</h2>
                        <p className="text-slate-700">
                            These Terms shall be governed by and construed in accordance with the laws of India, and any disputes arising out of
                            these Terms shall be resolved exclusively through binding arbitration as outlined above.
                        </p>
                    </section>

                    {/* Section 8: Termination */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-4">8. Termination</h2>
                        <p className="text-slate-700">
                            We reserve the right to terminate access to the Site or services at our discretion, including but not limited to
                            breaches of these Terms. Upon termination, any provisions meant to survive (e.g., intellectual property rights,
                            disclaimers, limitations of liability) shall remain in effect.
                        </p>
                    </section>

                    {/* Section 9: Changes to Terms */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-4">9. Changes to Terms</h2>
                        <p className="text-slate-700">
                            We may revise these Terms from time to time. The updated Terms will be posted on this page with an updated effective
                            date. You are advised to review this page periodically.
                        </p>
                    </section>

                    {/* Section 10: Contact Us */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-4">10. Contact Us</h2>
                        <p className="text-slate-700 mb-4">For questions about these Terms, contact us at:</p>
                        <p className="text-slate-700">Email: contact@trulyppc.com</p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-4">4. Arbitration Clause</h2>
                        <p className="text-slate-700 mb-4">
                            Any dispute, controversy, or claim arising out of or relating to these Terms, including the breach, termination, or validity thereof, shall be resolved by arbitration in accordance with the Arbitration and Conciliation Act, 1996.
                        </p>
                        <ul className="list-disc pl-6 text-slate-700">
                            <li>The arbitration shall be conducted by a single arbitrator appointed mutually by the parties. If the parties cannot agree, the arbitrator shall be appointed by the High Court of Chhattisgarh.</li>
                            <li>The venue of arbitration shall be Raipur, Chhattisgarh, and the proceedings shall be conducted in English.</li>
                            <li>The decision of the arbitrator shall be final and binding on both parties, and judgment on the arbitration award may be entered in any court of competent jurisdiction.</li>
                        </ul>
                    </section>

                    <p className="text-slate-600 mt-8">Last Updated: 11/12/2024</p>
                </div>
            </div>
            <Footer />
        </div>
    );
} 